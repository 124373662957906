<template>
	<p></p>
	<div class="thumbnail">
		<img alt="Mary Anning Reimagined" class="img-fluid mx-auto d-block" style="width:300px;" src="../assets/MAFrontCover3D.Transparent.png" />
	</div>
	<p class="fs-5">
		For the most current news please join us on <a href="https://www.facebook.com/profile.php?id=100092005477032" target="_blank">Facebook</a> and <a href="https://www.instagram.com/wolfgangegrulke/" target="_blank">Instagram</a>.
	</p>
	<div class="pt-3 mx-auto" style="max-width:75%">

		<div>
			<span class="fs-5 fw-bold">21 May 2024: BBC Radio Solent interview with Wolfgang Grulke</span>
			<br />

			<div class="embed-responsive embed-responsive-16by9">
				<iframe class="embed-responsive-item shadow-1-strong rounded mb-2" src="https://youtube.com/embed/9_k986GyqCk?autoplay=1&mute=1" allowfullscreen></iframe>
			</div>
		</div>

		<div class="mt-3">
			<span class="fs-5 fw-bold">8 May 2024: BBC TV interview with author and collector Wolfgang Grulke</span>
			<br />
			<div class="embed-responsive embed-responsive-16by9">
				<iframe class="embed-responsive-item shadow-1-strong rounded mb-2" src="https://youtube.com/embed/f7PJJOI0ipY?autoplay=1&mute=1" allowfullscreen></iframe>
			</div>
		</div>

		<div class="mt-3">
			<span class="fs-5 fw-bold">5 July 2023: Country Life</span>
			<br />
			An article on some UK fossil collectors and their collections.
		</div>

		<div class="container" style="max-width:75%">
			<div class="row d-flex flex-wrap align-items-center">
				<div class="col-sm-4">
					<a :href="getImageUrl('country-club-20230705-01.jpg')" download>
						<img src="../assets/Thumbnail.country-club-20230705-01.jpg"
							 class="shadow-1-strong rounded mb-2"
							 alt="Country Life 5 July 2023 cover" />
					</a>
				</div>
				<div class="col-sm-4">
					<a :href="getImageUrl('country-club-20230705-02.jpg')" download>
						<img src="../assets/Thumbnail.country-club-20230705-02.png"
							 class="w-100 shadow-1-strong rounded mb-2"
							 alt="Country Life 5 July 2023 p1" />
					</a>
				</div>
				<div class="col-sm-4">
					<a :href="getImageUrl('country-club-20230705-03.jpg')" download>
						<img src="../assets/Thumbnail.country-club-20230705-03.png"
							 class="w-100 shadow-1-strong rounded mb-2"
							 alt="Country Life 5 July 2023 p2" />
					</a>
				</div>
			</div>
		</div>
	</div>
		<div class="mt-3">
			<span class="fs-5 fw-bold">21 May 2023: Lyme Regis Museum</span>
			<br />
			The book launch
		</div>
		<div class="mx-auto pt-3" style="max-width:50%">
			<img alt="Signing books" class="rounded shadow img-fluid mx-auto d-block" src="../assets/WEG-BookSigning.jpg" />
		</div>
		<div class="mx-auto pt-5" style="max-width:50%">
			<a href="https://youtu.be/0QZnB-HPfa4" target="_blank"><img alt="Youtube" class="rounded shadow img-fluid mx-auto d-block" src="../assets/MaryAnning.YouTube.png" />https://youtu.be/0QZnB-HPfa4</a>
		</div>
		<div class="pt-3">
			<a href="https://www.telegraph.co.uk/news/2023/04/01/palaeontologist-mary-anning-young-pretty-midjourney-ai/?fbclid=IwAR1fVqovR0zdYUBl_nnBArX1d0hGDgROO3TqgDczws3_HEkktxm5x8abK4c" target="_blank">The Sunday Telegraph - 2 April 2023<img alt="Sunday Telegraph - 2 April 2023" class="rounded shadow img-fluid mx-auto d-block" src="../assets/SundayTelegraph.20230402.jpg" /></a>
		</div>
</template>

<script>
	export default {
		name: 'News',
		methods: {
			getImageUrl: function (path) {
				let images = require.context('../assets/', false, /\.png$|\.jpg$/)
				return images("./" + path)
			}
		}
	}
</script>

<style scoped>
	a:link {
		padding: 0px;
	}
</style>
