<template>
	<p></p>
	<div class="thumbnail pb-5">
		<img alt="Mary Anning Reimagined" class="img-fluid mx-auto d-block" style="width:300px;" src="../assets/MAFrontCover3D.Transparent.png" />
	</div>
	<div class="mx-auto w-75">
		<span class="text-uppercase">Wolfgang Grulke</span> is an award-winning author on the natural world, the future of business and technology.
		<br />
		<br />
		He is the founder of the FutureWorld think tank and author of the prescient 2000 book <span class="fw-bold">10 Lessons from the Future</span> which anticipated the impact of the internet and mobile technologies.
		<br />
		<br />
	</div>
	<div class="container-fluid w-75">
		<div class="row">
			<div class="col-xl-6 col-lg-6 col-md-6 align-items-center fst-italic">
				Wolfgang's <a href="https://beyondextinction.atone.org" target="_blank">Deep Time Trilogy</a> was voted the best non-fiction book series in 2020 and is based on his renowned fossil collection, part of which is shown in the images below.
			</div>
			<div class="col-xl-6 col-lg-6 col-md-6">
				<a href="https://beyondextinction.atone.org" target="_blank">
					<img class="img-fluid w-100 rounded" src="../assets/DeepTimeTrilogy.png" />
				</a>
			</div>
		</div>
	</div>
	<br />
	<br />
	<div class="container-fluid w-50">
		<div class="row">
			<div class="col-xl-6 col-lg-6 col-md-6">
				<a href="https://adornedbynature.atone.org" target="_blank">
					<img class="img-fluid w-100 rounded" style="max-width:50%;" src="../assets/AdornedByNature.png" />
				</a>
			</div>
			<div class="col-xl-6 col-lg-6 col-md-6 align-items-center flex-wrap fst-italic">
				<br/>
				His 2022 book <a href="https://adornedbynature.atone.org" target="_blank">Adorned by Nature</a> celebrates the magnificent adornment culture of the peoples of the South Pacific.
			</div>
		</div>
	</div>
	<br />
	<br />
	He lives in the Dorset countryside with his wife, Teresa Martin, and two dogs.


	<p class="pt-5 fs-6">
		<b>
			To arrange an interview and/or a visit to his renowned fossil collection in Dorset, please <a href="m&#97;&#105;&#108;&#116;&#79;&#58;Wolfgang&#46;Grulke&#64;gmail&#46;com">contact</a> the author directly.
		</b>
		<br />
		Photos can be downloaded by clicking on the thumbnails below:
	</p>

	<!-- Gallery -->
	<div class="container" style="max-width:50%">
		<div class="row">
			<div class="col-sm-2">
				<a :href="getImageUrl('WEG-in-museum.jpg')" download>
					<img src="../assets/Thumbnail.WEG-in-museum.png"
						 class="w-100 shadow-1-strong rounded mb-4"
						 alt="1" />
				</a>
			</div>
			<div class="col-sm-2">
				<a :href="getImageUrl('WEG-image-small.jpg')" download>
					<img src="../assets/Thumbnail.WEG-image-small.png"
						 class="w-100 shadow-1-strong rounded mb-4"
						 alt="2" />
				</a>
			</div>
			<div class="col-sm-2">
				<a :href="getImageUrl('WEG-Lyme-Regis-Monmouth-beach-v2.jpg')" download>
					<img src="../assets/Thumbnail.WEG-Lyme-Regis-Monmouth-beach-v2.png"
						 class="w-100 shadow-1-strong rounded mb-4"
						 alt="3" />
				</a>
			</div>
			<div class="col-sm-2">
				<a :href="getImageUrl('WEG-in-museum-collection.jpg')" download>
					<img src="../assets/Thumbnail.WEG-in-museum-collection.png"
						 class="w-100 shadow-1-strong rounded mb-4"
						 alt="4" />
				</a>
			</div>
			<div class="col-sm-2">
				<a :href="getImageUrl('WEG-keynote-photo-by-Nannette-Vinson.jpg')" download>
					<img src="../assets/Thumbnail.WEG-keynote-photo-by-Nannette-Vinson.png"
						 class="w-100 shadow-1-strong rounded mb-4"
						 alt="5" />
				</a>
			</div>
			<div class="col-sm-2">
				<a :href="getImageUrl('WEG-with-kamikaze-ichthyosaur.jpg')" download>
					<img src="../assets/Thumbnail.WEG-with-kamikaze-ichthyosaur.png"
						 class="w-100 shadow-1-strong rounded mb-4"
						 alt="6" />
				</a>
			</div>
			<div class="col-sm-12">
				<a :href="getImageUrl('WEG-FutureWorld-Singapore.jpg')" download>
					<img src="../assets/Thumbnail.WEG-FutureWorld-Singapore.png"
						 class="w-20 shadow-1-strong rounded mb-4"
						 alt="7" />
				</a>
			</div>

		</div>
	</div>
	<!-- Gallery -->


</template>

<script>
	export default {
		name: 'Author',
		methods: {
			getImageUrl: function (path) {
				let images = require.context('../assets/', false, /\.png$|\.jpg$/)
				return images("./" + path)
			}
		}
	}
</script>

<style scoped>
	a:link {
		padding: 0px;
	}
</style>