<template>
	<p></p>
	<div class="thumbnail">
		<img alt="Mary Anning Reimagined" class="img-fluid mx-auto d-block" style="width:300px;" src="../assets/MAFrontCover3D.Transparent.png" />
	</div>

	<p class="endorsementText">
		"This book is a must... this amazing story told using brand-new AI images, adds depth to Anning's life and helps readers visualize her achievements. Easy to read and packed with intrigue. A thoroughly engaging and enjoyable read."
		<br />
		<span class="endorsementSource" style="color: var(--color-turquiose);">Childrens' Book Reviews:</span><span> <a class="endorsementSourceWorkTitle" href="https://schoolreadinglist.co.uk/childrens-book-reviews/she-sold-seashells/" target="_blank">https://schoolreadinglist.co.uk/childrens-book-reviews/she-sold-seashells/</a></span>
	</p>

	<p class="endorsementText">
		"This is a brilliant use of new technology to enhance our understanding and connection with this fantastically great woman. A great addition to (school) resources."
		<br />
		<span class="endorsementSource" style="color: var(--color-blue);">Kids Books to Life Instagram Reel review:</span><span> <a class="endorsementSourceWorkTitle" href="https://www.instagram.com/p/C7jz2_MK0sb/" target="_blank">https://www.instagram.com/p/C7jz2_MK0sb/</a></span>
	</p>

	<p class="endorsementText">
		"A series of short texts, fascinating facts, stunning images help solidify her place as a key role model and person of significance in history and science. A beautiful book."
		<br />
		<span class="endorsementSource" style="color: var(--color-orange);">Scope for Imagination review:</span><span> <a class="endorsementSourceWorkTitle" href="https://scopeforimagination.co.uk/2024/05/20/she-sold-seashells-the-curious-mary-anning-re-imagined-by-wolfgang-grulke/" target="_blank">https://scopeforimagination.co.uk/2024/05/20/she-sold-seashells-the-curious-mary-anning-re-imagined-by-wolfgang-grulke/</a></span>
	</p>

	<p class="endorsementText">
		"Fascinating. Images created using AI help bring her story to life by allowing readers to imagine what she may have looked like in her earlier years. I like the format - each double page spread details a mini chapter in her life, with her age displayed for reference. It would make a brilliant book to use in classroom."
		<br />
		<span class="endorsementSource" style="color: var(--color-darkgreen);">Children's Fiction review:</span><span> <a class="endorsementSourceWorkTitle" href="https://www.instagram.com/p/C7PPWmJqnGw/?img_index=1" target="_blank">https://www.instagram.com/p/C7PPWmJqnGw/?img_index=1</a></span>
	</p>

	<p class="endorsementText">
		"A great teaching tool to learn about her incredible life."
		<br />
		<span class="endorsementSource" style="color: var(--color-pink);">Mrs Brown's Book Box:</span><span> <a class="endorsementSourceWorkTitle" href="https://www.instagram.com/p/C7PYaGAqxqp/?img_index=1" target="_blank">https://www.instagram.com/p/C7PYaGAqxqp/?img_index=1</a></span>
	</p>

	<p class="endorsementText">
		"A marvellous book.  Striking AI imagery. This book should be in every school library."
		<br />
		<span class="endorsementSource" style="color: var(--color-turquiose);">Libraries 4 Schools review:</span><span> <a class="endorsementSourceWorkTitle" href="https://www.instagram.com/p/C7OoGJFKfG6/" target="_blank">https://www.instagram.com/p/C7OoGJFKfG6/</a></span>
	</p>

	<p class="endorsementText">
		"A brilliant new biography of the pioneering fossil hunter. There is so much to learn here. This book is a perfect example of how AI can be used in a positive and creative way -  definitely makes Mary's story more accessible for young readers."
		<br />
		<span class="endorsementSource" style="color: var(--color-green);">Books for Little Bookworms review and Instagram story:</span><span> <a class="endorsementSourceWorkTitle" href="https://www.instagram.com/p/C7O-CGnqwyT/" target="_blank">https://www.instagram.com/p/C7O-CGnqwyT/</a></span>
	</p>

	<p class="endorsementText">
		"Mary Anning has long been one of my heroes. Mary was a pioneer who defied her gender and background and, through resilience, courage and determination, became an inspirational role model. In 2010, the Royal Society named Mary one of ten most influential female scientists of all time. This book gives a new insight into her life and work. Grulke has created a series of AI images that bring her story to life. This is a first-rate biography. A gem."
		<br />
		<span class="endorsementSource" style="color: var(--color-turquiose);">Brenda Marshall,</span><span class="endorsementSourceWorkTitle"> The English Association</span>
	</p>
	<p class="endorsementText">
		"I always look forward to a new book by Wolfgang.  This book is a splendid addition to other biographies of Mary Anning by Tom Sharpe, Hugh Torrens and Patricia Pierce, to name but a few. Using AI, Wolfgang has created images to illustrate the young Mary's life. A novel approach, very accessible and an enjoyable read."
		<br />
		<span class="endorsementSource" style="color: var(--color-blue);">Kelvin Huff,</span><span class="endorsementSourceWorkTitle"> Dorset Geologists Association</span>
	</p>
	<p class="endorsementText">
		"I love this man's books! They are fascinating works of art, gorgeous imagery and text that is factually informative for any scholar, yet any curious reader will be absorbed..."
		<br />
		<span class="endorsementSource" style="color: var(--color-orange);">Chris Bartlett </span><span class="endorsementSourceWorkTitle">(via Facebook)</span>
	</p>

	<p class="endorsementText">
		She Sold Seashells ...and dragons should be in schools all over the world, and given as a gift to anyone, young or old, who has an interest in Mary, fossils, or palaeontology. It is beautifully illustrated, concisely and logically written, and, above all, it brilliantly conveys the fierce curiosity and wistful sense of injustice that Mary must have felt at being unable to fully join the emerging world of geology. Two hundred years ago, she was ahead of her time. Now, finally, her time has come.
		<br />
		<span class="endorsementSource" style="color: var(--color-darkgreen); ">GeoScientist,</span><span class="endorsementSourceWorkTitle"> the journal of the Geological Society, 19 September 2023</span>
	</p>
	<p class="endorsementText">
		"A triumph! Concise, empathetic and meticulously accurate. Powerful AI imagery is perfect
		for readers to step into Mary's shoes as she makes important fossil finds in her teens and
		twenties... it's just like being there with her at the birth of palaeontology."
		<br />
		<span class="endorsementSource" style="color: var(--color-pink);">Dr Paul Davis, </span><span class="endorsementSourceWorkTitle">Geology Curator at the Lyme Regis Museum</span>
	</p>
	<p class="endorsementText">
		"An ingenious project to bring Mary Anning to life."
		<br />
		<span class="endorsementSource" style="color: var(--color-turquiose);">Sir David Attenborough, </span><span class="endorsementSourceWorkTitle">Renowned naturalist and broadcaster</span>
	</p>
	<p class="endorsementText">
		"A brilliant re-imagining and re-telling of the fascinating story of Mary Anning which I hope will attract and inform a new audience."
		<br />
		<span class="endorsementSource" style="color: var(--color-blue);">Dr Jonathan Evans, </span><span class="endorsementSourceWorkTitle">Chair of Trustees, Lyme Regis Museum.</span>
	</p>
	<p class="endorsementText">
		"Genuinely inspirational. Bravo!"
		<br />
		<span class="endorsementSource" style="color: var(--color-green);">Alastair Poulain, </span><span class="endorsementSourceWorkTitle">Deputy Head, Sherborne Preparatory School.</span>
	</p>
	<p class="endorsementText">
		"Mary Anning: Uncovering the past, inspiring the future."
		<br />
		<span class="endorsementSource" style="color: var(--color-orange);">ChatGPT, </span><span class="endorsementSourceWorkTitle">AI research bot</span>
	</p>
</template>

<script>
	export default {
		name: 'Reviews',
	}
</script>

<style scoped>
	.endorsementText {
		padding-top: 15px;
		font-style: italic;
		text-align: center;
	}

	.endorsementSource {
		font-size: 12pt;
	}

	.endorsementSourceWorkTitle {
		font-size: 10pt;
		color: var(--color-dark-gray);
	}
</style>