<template>
	<p></p>
	<div class="thumbnail">
		<img alt="Mary Anning Reimagined" class="img-fluid mx-auto d-block" style="width:300px;" src="../assets/MAFrontCover3D.Transparent.png" />
	</div>
	<p>
		This information will be constantly updated as new material emerges.
	</p>
	<p>
		The major museums along Dorset's Jurassic Coast where you can further explore Mary Anning's story.
	</p>
	<img alt="Dorset map" class="img-fluid mx-auto d-block" style="max-width:50%;" src="../assets/Dorset.Map.png" />
	<br/>
	<p>
		<a href="https://www.LymeRegisMuseum.co.uk" target="_blank">Lyme Regis Museum</a>
	</p>
	<p>
		<a href="https://www.DorsetMuseum.org" target="_blank">Dorset Museum</a>
	</p>
	<p>
		<a href="https://www.TheEtchesCollection.org" target="_blank">Etches Collection</a>
	</p>
	<p>
		<a href="https://charmouth.org/chcc/" target="_blank">Charmouth Heritage Coast Centre</a>
	</p>
	<p>
		<a href="https://www.jurassiccoast.org/" target="_blank">Jurassic Coast Trust</a>
	</p>
	<p>&nbsp;</p>
	<h3>ERRATA</h3>
	<p>None found so far!</p>

</template>

<script>
	export default {
		name: 'Content',
	}
</script>

<style scoped>
</style>