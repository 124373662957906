<template>
	<div class="float-start d-flex flex-wrap">
		<img :src="getImageUrl()" style="max-width:100%;" />
	</div>
</template>

<script>
	export default {
		name: 'ImageView',
		data() {
			return {
				ImageName: null
			}
		},
		props: {
			id: String
		},
		methods: {
			getImageUrl: function () {
				if (this.$route.params.id == null || this.$route.params.id == "undefined")
					return;

				this.ImageName = this.$route.params.id;

				let images = require.context('../assets/', false, /\.png$|\.jpg|\.jpeg$/);
				return images("./" + this.ImageName);
			}
		}
	}
</script>

<style scoped>
</style>