<template>
	<div class="container">

		<div id="nav">
			<nav class="container-fluid navbar navbar-expand-lg">
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" v-on:click="toggleMenu()">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div id="navbarSupportedContent" class="navbar-collapse justify-content-center" :class="IsMenuVisible?'':'collapse'">
					<div class="navbar-nav mr-auto">
						<router-link class="menu" to="/" v-on:click="toggleMenu()">Home</router-link>
						<router-link class="menu" to="/news" v-on:click="toggleMenu()">News</router-link>
						<router-link class="menu" to="/samples" v-on:click="toggleMenu()">Sample pages</router-link>
						<router-link class="menu" to="/order" v-on:click="toggleMenu()">Buy now</router-link>
						<router-link class="menu" to="/author" v-on:click="toggleMenu()">Author</router-link>
						<router-link class="menu" to="/reviews" v-on:click="toggleMenu()">Reviews</router-link>
						<router-link class="menu" to="/content" v-on:click="toggleMenu()">Content+++</router-link>
					</div>
				</div>
			</nav>
		</div>

		<router-view />
		<sub>
			Copyright &copy; AtOne Communications {{CurrentYear}}
		</sub>
		<br />
		<br />
	</div>

</template>

<script>
	import Home from './components/Home.vue'
	import News from './components/News.vue'
	import Samples from './components/Samples.vue'
	import Author from './components/Author.vue'
	import Reviews from './components/Reviews.vue'
	import Content from './components/Content.vue'
	import Order from './components/Order.vue'
	import ImageView from './components/Image.vue'

	export default {
		name: 'App',
		components: {
			Home,
			News,
			Samples,
			Author,
			Reviews,
			Content,
			Order,
			ImageView
		},
		data() {
			return {
				CurrentYear: new Date().getFullYear(),
				IsMenuVisible: false
			}
		},
		methods: {
			toggleMenu() {
				this.IsMenuVisible = !this.IsMenuVisible;
			}
		}
	}
</script>

<style>
	:root {
		--color-orange: #e05800;
		--color-pinkred: #e82b32;
		--color-red: #ff0000;
		--color-turquiose: #1baac8;
		--color-goldbrown: #bf6f1a;
		--color-yellow: #e2a535;
		--color-green: #9d9d00;
		--color-darkgreen: #22912e;
		--color-blue: #1455ac;
		--color-pink: #b922a0;
		--color-dark-gray: #333333;
		--color-text: #10181e;
		--color-text-mobile: #000000;
		--color-background: #b4daf4;
		--color-link: #3d4a50;
		--color-link-hover: #333333;
	}

	#app {
		font-family: Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: var(--color-text);
		margin-top: 60px;
		background-color: var(--color-background);
		margin: auto;
		font-size: 12pt;
		font-weight: 300;
	}

	@media screen and (max-width: 480px) {
		#app {
			color: var(--color-text-mobile);
		}
	}

	#nav {
		padding: 30px;
	}

		#nav a,
		#nav a:hover {
			font-style: italic;
		}

		#nav a {
			color: var(--color-link);
		}

			#nav a:hover {
				color: var(--color-link-hover);
			}


			#nav a.menu {
				font-size: larger;
				font-style: normal;
				color: var(--color-link);
				text-decoration: none;
				padding: 10px;
				border-radius: 4px;
			}

				#nav a.menu:hover {
					color: var(--color-link-hover);
				}

				#nav a.menu.router-link-exact-active {
					color: #c2f0ff;
					background-color: var(--color-link);
				}



	/*--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));*/
</style>
