<template>
	<p></p>
	<div class="multi-carousel vertical">
		<div class="multi-carousel-inner">
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage01.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="1" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage02.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="2" />
			</div>

			<div class="multi-carousel-item">
				<img src="../assets/SampleImage03.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="3" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage04.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="4" />
			</div>

			<div class="multi-carousel-item">
				<img src="../assets/SampleImage05.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="5" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage06.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="6" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage07.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="7" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage08.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="8" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage09.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="9" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage10.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="10" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage11.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="11" />
			</div>
			<div class="multi-carousel-item">
				<img src="../assets/SampleImage12.png"
					 class="w-100 shadow-1-strong rounded mb-4"
					 alt="12" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Samples',
	}
</script>

<style scoped>
</style>