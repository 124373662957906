import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/news',
		name: 'News',
		component: () => import('../components/News.vue')
	},
	{
		path: '/samples',
		name: 'Samples',
		component: () => import('../components/Samples.vue')
	},
	{
		path: '/order',
		name: 'Order',
		component: () => import('../components/Order.vue')
	},
	{
		path: '/author',
		name: 'Author',
		component: () => import('../components/Author.vue')
	},
	{
		path: '/reviews',
		name: 'Reviews',
		component: () => import('../components/Reviews.vue')
	},
	{
		path: '/content',
		name: 'Content',
		component: () => import('../components/Content.vue')
	},
	{
		path: '/image/:id',
		name: 'ImageView',
		component: () => import('../components/Image.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router