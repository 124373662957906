<template>

	<div class="container-fluid">
		<div class="row">
			<div class="col-xl-6 col-lg-6 col-md-6">
				<img class="img-fluid mx-auto d-block" alt="Mary Anning Reimagined front page" src="../assets/MAFrontCover.png" />
			</div>
			<div class="col-xl-6 col-lg-6 col-md-6 d-flex align-items-center">
				<div>
					<span class="text-uppercase fs-1 lh-lg">
						She Sold Seashells
						<br />
						...and dragons
					</span>
					<div>
						&nbsp;
					</div>
					<span class="fs-2 text-secondary">
						The curious Mary Anning. Re-imagined.
					</span>
				</div>

			</div>
		</div>


		<div class="row">
			<div class="col-xl-6 col-lg-6 col-md-6 d-flex align-items-center">
				<div>
					<span class="fs-2">
						A new book by Wolfgang Grulke
					</span>
					<br />
					96 pages, hard cover, lavishly illustrated
					<br />
					198 x199 x 13 mm, 350g
					<br />
					ISBN 978-1-9160394-5-2
					<br />
					Publication Date: 21 May 2023 - Mary Anning's 224th birthday
					<br />
					<br />
					<small>Retail price: UK &#163;12, US $15, Australia $25, plus shipping</small>
				</div>

			</div>
			<div class="col-xl-6 col-lg-6 col-md-6">
				<img class="img-fluid mx-auto d-block" alt="Mary Anning Reimagined back page" src="../assets/MABackCover.png" />
			</div>

		</div>

	</div>

	<p class="pt-4 text-start">
		Mary Anning lived about 200 years ago. A bright girl from a poor family living in a small town on the south coast of England, she became a successful fossil hunter, dealer and self-taught scientist.
		<br />
		<br />
		While not fully recognised in her own lifetime because she was a woman and of the wrong social class, she is now heralded as a leading figure in the science of palaeontology, which at the time did not even have a name. Her story is well known but there is only one image of her, painted shortly before her death at age 47.
		<br />
		<br />
		She made many of her important discoveries of 'mythical sea dragons' as a teenager and in her twenties.
		<br />
		<br />
		Now, AI technology enables us to tell Mary's exceptional story with brand-new images - for curious readers of all ages and to help inspire today's
		young fossil entrepreneurs who follow in her wake.
		<br />
		<br />
		See a summary video <a href="https://youtu.be/0QZnB-HPfa4" target="_blank">here</a>.
	</p>

	<p class="fs-4 text-uppercase">
		Created in partnership with the Lyme Regis Museum, the spiritual home of Mary Anning
	</p>
	<div class="mt-2">
		<a href="https://www.LymeRegisMuseum.co.uk" target="_blank">
			<img class="img-fluid mx-auto d-block" src="../assets/Logo.LymeRegisMuseum.png" />
		</a>
	</div>

	<p class="pt-3">
		The <a href="https://www.LymeRegisMuseum.co.uk" target="_blank">Lyme Regis Museum</a> is the spiritual home of Mary Anning and is dedicated to sharing the natural beauty and history of the Jurassic Coast. This book offers an additional, and extraordinary glimpse into the life of a trailblazer who defied her gender and social class to become a pioneer in the field of palaeontology.
	</p>
	<p class="fst-italic">
		"Built on the site of Mary Anning's home, Lyme Regis Museum stands in the heart of the town, just where the beautiful coastlines of Dorset and Devon meet on the Jurassic Coast World Heritage Site. The sea views from the museum's new Mary Anning Wing are stunning, and the extra space has provided all the facilities you'd expect in a modern museum - the perfect space for families and schools."
	</p>

</template>

<script>
	export default {
		name: 'Home',
	}
</script>

<style scoped>
</style>